import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileSocial = _resolveComponent("ProfileSocial")!
  const _component_Description = _resolveComponent("Description")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.store.dark ? 'mainPageContainer' : 'mainPageContainerLight')
  }, [
    _createVNode(_component_ProfileSocial),
    _createVNode(_component_Description)
  ], 2))
}