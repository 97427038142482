<template>
  <div :class="store.dark ? 'contactContainer' : 'contactContainerLight'">
    <h3>
      <span>contact info</span>
    </h3>
    ===============
    <div class="contactLayout">
      <div>
        <div>
          <span class="textBlue">### General ###</span>
        </div>
        <div class="contactBlock">
          Visit one of my web pages:
          <ul>
            <li>- 
              <a href="http://github.com/adamryan1983" alt="github" target="_blank">
                My Github 
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="red" :class="store.dark ? 'socialIcon' : 'socialIconLight'"><title>GitHub</title><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
                </svg>
              </a>
            </li>
            <li>- 
              <a href="http://thisisadamryan.me" alt="portfolio">
                Adam Ryan Portfolio
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div>
          <span class="textBlue">### Social Media ###</span>
        </div>
        <div class="contactBlock">
          Visit me on one of many different sites:
          <ul>
            <li>- 
              <a href="http://instagram.com/therunningdev" alt="instagram" target="_blank">
                Instagram
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="store.dark ? 'socialIcon' : 'socialIconLight'"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/>
                </svg>
              </a>
            </li>
            <li>- 
              <a href="http://twitter.com/adamryan1983" alt="twitter" target="_blank">
                Twitter
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="store.dark ? 'socialIcon' : 'socialIconLight'"><title>Twitter</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
                </svg>
              </a>
            </li>
            <li>- 
              <a href="http://linkedin/in/adamryan1983" alt="fb" target="_blank">
                LinkedIn
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="store.dark ? 'socialIcon' : 'socialIconLight'"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
          
      <div>
        <div>
          <span class="textBlue">### Email Me ###</span>
        </div>
        <div class="contactBlock">
          Choose an email:
          <ul>
            <li>- 
              <a href="mailto:adam@thisisadamryan.me" alt="emailpersonal">
                Personal Email 
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="store.dark ? 'socialIcon' : 'socialIconLight'"><title>Mail.Ru</title><path d="M15.61 12c0 1.99-1.62 3.61-3.61 3.61-1.99 0-3.61-1.62-3.61-3.61 0-1.99 1.62-3.61 3.61-3.61 1.99 0 3.61 1.62 3.61 3.61M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12c2.424 0 4.761-.722 6.76-2.087l.034-.024-1.617-1.879-.027.017A9.494 9.494 0 0 1 12 21.54c-5.26 0-9.54-4.28-9.54-9.54 0-5.26 4.28-9.54 9.54-9.54 5.26 0 9.54 4.28 9.54 9.54a9.63 9.63 0 0 1-.225 2.05c-.301 1.239-1.169 1.618-1.82 1.568-.654-.053-1.42-.52-1.426-1.661V12A6.076 6.076 0 0 0 12 5.93 6.076 6.076 0 0 0 5.93 12 6.076 6.076 0 0 0 12 18.07a6.02 6.02 0 0 0 4.3-1.792 3.9 3.9 0 0 0 3.32 1.805c.874 0 1.74-.292 2.437-.821.719-.547 1.256-1.336 1.553-2.285.047-.154.135-.504.135-.507l.002-.013c.175-.76.253-1.52.253-2.457 0-6.617-5.383-12-12-12"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useThemeStore } from '../stores/themeStore'

export default defineComponent({

  setup () {
    const store = useThemeStore()
    store.dark
    return {
      store
    }
  },
})
</script>

<style lang="scss" scoped>
@use '../constants/variables.scss' as *;
.socialIcon {
  height: 1rem;
  fill: $secondaryColor;
  &:hover {
    fill: $thirdColor;
  }
  &Light {
    @extend .socialIcon;
    fill: $secondaryColor-light;
  }
}

.contactContainer {
  padding: 3rem 5rem;
  & h1,h2,h3,h4,h5 {
    color: $headerColor;
  }
  & a {
    color: $fourthColor;
    text-decoration: none;
    &:hover {
      background: $thirdColor
    }
  }
  &Light {
    @extend .contactContainer;
    & h1,h2,h3,h4,h5 {
      color: $headerColor-light;
    }
    & a {
      color: $fourthColor-light;
      text-decoration: none;
      &:hover {
        background: $thirdColor-light
      }
    }
  }
}
.textBlue {
  color: #60A5FA;
}

.contactLayout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  padding: 3rem 0 2rem 0;;

  & li {
    list-style: none;
    padding: 0.3rem 0;
  }
  & .contactBlock {
    padding: 0.6rem 0 2.5rem 0;
  }
}

//responsive queries
@media only screen and (max-width: 1024px) {

  .contactLayout {
    width: 100%;
    justify-content: space-between;
    & > * {
      padding: 0 1rem;
      width: 50%;
    }
  }
  .contactBlock {
    padding: 4rem;
  }
}

@media only screen and (max-width: 800px) {
  .contactLayout {
    flex-direction: column;
    & > * {
      padding: 1rem 0 0 0;
    }
  }
}
</style>